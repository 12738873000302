import { template as template_ef363f505f67467e860700fa90bdd44c } from "@ember/template-compiler";
export default template_ef363f505f67467e860700fa90bdd44c(`
  <ul class="responsive-list-wide-wrap">
    {{yield}}
  </ul>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
