import { template as template_067e9b0ff8c94d8fa4ab06e59cd6281d } from "@ember/template-compiler";
import htmlUnsafe from 'mon-pix/helpers/html-unsafe';
export default template_067e9b0ff8c94d8fa4ab06e59cd6281d(`
  <div class="element-text">
    {{htmlUnsafe @text.content}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
