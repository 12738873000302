import { template as template_5318f2912d184ee0af2dedfc04be94ad } from "@ember/template-compiler";
export default template_5318f2912d184ee0af2dedfc04be94ad(`
  <div class="campaign-step">
    <img class="campaign-step__image" role="presentation" src={{@step.image.src}} width="60" height="60" />
    <h3 class="campaign-step__title">{{@step.title}}</h3>
    <p class="campaign-step__description">{{@step.description}}</p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
