import { template as template_645c387fea0c4571803a8140fee2d1c5 } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { t } from 'ember-intl';
import { eq } from 'ember-truth-helpers';
import AssessmentsLiveAlert from '../assessments/live-alert';
import FeedbackPanel from '../feedback-panel';
import FeedbackPanelV3 from '../feedback-panel-v3';
import ChallengeItem from './item';
export default class ChallengeContent extends Component {
    @tracked
    isLiveAlertButtonEnabled = true;
    @action
    handleChallengeSubmit() {
        this.isLiveAlertButtonEnabled = false;
    }
    static{
        template_645c387fea0c4571803a8140fee2d1c5(`
    <div
      class="focus-zone-warning
        {{if @isFocusedChallengeAndUserHasFocusedOutOfChallenge 'focus-zone-warning--triggered'}}"
      data-challenge-id="{{@challenge.id}}"
      {{on "mouseenter" @hideOutOfFocusBorder}}
      {{on "mouseleave" @showOutOfFocusBorder}}
    >

      <ChallengeItem
        @challenge={{@challenge}}
        @assessment={{@assessment}}
        @answer={{@answer}}
        @timeoutChallenge={{@timeoutChallenge}}
        @resetAllChallengeInfo={{@resetAllChallengeInfo}}
        @resetChallengeInfoOnResume={{@resetChallengeInfoOnResume}}
        @onFocusIntoChallenge={{fn @setFocusedOutOfChallenge false}}
        @onFocusOutOfChallenge={{fn @setFocusedOutOfChallenge true}}
        @onFocusOutOfWindow={{@focusedOutOfWindow}}
        @hasFocusedOutOfWindow={{@hasFocusedOutOfWindow}}
        @isFocusedChallengeAndUserHasFocusedOutOfChallenge={{@isFocusedChallengeAndUserHasFocusedOutOfChallenge}}
        @isTextToSpeechActivated={{@isTextToSpeechActivated}}
        @onChallengeSubmit={{this.handleChallengeSubmit}}
      />

      {{#unless @assessment.hasOngoingCompanionLiveAlert}}
        <div class="challenge__feedback" role="complementary">
          {{#if (eq @assessment.certificationCourse.version 3)}}
            <FeedbackPanelV3
              @submitLiveAlert={{@submitLiveAlert}}
              @assessment={{@assessment}}
              @isEnabled={{this.isLiveAlertButtonEnabled}}
            />
          {{else}}
            <FeedbackPanel @assessment={{@assessment}} @challenge={{@challenge}} />
          {{/if}}
        </div>
      {{/unless}}

      {{#if @assessment.hasOngoingCompanionLiveAlert}}
        <AssessmentsLiveAlert @message={{t "pages.challenge.live-alerts.companion.message"}} />
      {{/if}}
    </div>

    {{#if @isFocusedChallengeAndUserHasFocusedOutOfChallenge}}
      <div class="focus-zone-warning__overlay" />
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
